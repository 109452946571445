.projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Sales-i custom font */
@font-face {
  font-family: 'CustomFont';
  src: url('../assets/Urbanist-Medium.ttf') format('truetype');
}

.custom-font {
  font-family: 'CustomFont', sans-serif;
}
