.project-box {
  display: flex;
  text-align: left;
  width: 100%;
}

.project-inner-box {
  flex: 1;
  padding: 1rem; 
}

.project-image-box {
  flex: 0 0 auto;
}

img {
  height: auto;
}

h2, p {
  margin: 0;
}

.tech-label {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}
