.whoami {
    display: flex;
    height: 100vh;
  }
  
  .who-text-box {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .who-text-box-outer {
    flex: 1;
  }
  
  .inner-box {
    padding: 20px;
    max-width: 80%;
    text-align: left;
  }
  
  .image-box img {
    max-width: 100%;
    max-height: 100%;
  }
  